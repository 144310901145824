.@{aputure-prefix}-manual {
    .@{aputure-prefix}-query-title {
        font-size: 24px;
        font-weight: 800;
        color: #FAFAFA;
        text-align: center;
        margin-bottom: 0;
    }

    .@{aputure-prefix}-query-link {
        color: #E6E6E6;
        font-weight: 500;
        display: inline-flex;
        align-items: center;
        position: absolute;
    }

    .@{aputure-prefix}-manual-query-form {
        margin: 75px auto 0;
        max-width: 694px;
        width: 80%;
    }

    .form-item-help {
        color: #808080;
        font-size: @font-size-base;
        display: flex;
        align-items: center;
        margin-top: 14px;
    }
}

.@{aputure-prefix}-manual-query-form {
    .@{ant-prefix}-input {
        height: 44px;
    }

    .@{ant-prefix}-form-item-label>label {
        font-size: 18px;

        .@{ant-prefix}-form-item-optional {
            color: #CCC;
            font-size: @font-size-base;
        }
    }

    .@{aputure-prefix}-submit-button {
        width: 180px;

        &[disabled] {
            color: #FFFFFF;
            border-color: @primary-color;
            background: @primary-color;
            opacity: 0.3;
        }

        &-wrapper {
            text-align: center;
            padding-top: 40px;
        }
    }
}

.@{aputure-prefix}-submit-result {
    padding-bottom: 20px;
    overflow: hidden;

    &-box {
        margin: 70px auto 0;
        max-width: 814px;
        width: 90%;
        border: 1px solid @border-color-base;
        border-radius: @border-radius-base;
        text-align: center;

        +.@{aputure-prefix}-manual-query-back {
            margin-top: 70px;
        }

        .icon-email-success {
            margin: 80px auto 40px;
        }
    }

    .result-description {
        font-weight: 500;
        font-size: 16px;
        color: #E6E6E6;
        margin: 0 auto 100px;
    }

    .@{aputure-prefix}-manual-query-back {
        color: #E6E6E6;
        font-weight: 500;
        display: inline-flex;
        align-items: center;
        float: right;
        cursor: pointer;
    }
}


@media screen and (max-width: @phone-max-width) {
    .@{aputure-prefix}-manual {
        .@{aputure-prefix}-query-title {
            font-size: calc(20px + 0.15vw);
            margin-top: 30px;
        }

        .@{aputure-prefix}-manual-query-form {
            margin: 30px auto 0;
            width: 95%;
        }

        .@{aputure-prefix}-container {
            padding-bottom: 60px;
        }
    }

    .@{aputure-prefix}-manual-query-form {
        .@{ant-prefix}-input {
            height: 40px;
        }
        .@{aputure-prefix}-submit-button {
            width: 140px;
            height: 44px;
            padding: 4px 15px;
            font-size: 16px;

            &-wrapper {
                padding-top: 20px;
            }
        }

        .@{ant-prefix}-form-item-label {
            padding-bottom: 10px;

            >label {
                font-size: 16px;

                .@{ant-prefix}-form-item-optional {
                    font-size: 12px;
                }
            }
        }

        .@{ant-prefix}-form-item {
            margin-bottom: 28px;
        }

        .@{aputure-prefix}-submit-button {
            width: 140px;
        }


        .@{ant-prefix}-upload-list-picture-card {
            &.@{ant-prefix}-upload-list {
                margin-left: -6px;
                margin-right: -6px;
            }


            .@{ant-prefix}-upload-list-picture-card-container {
                margin: 0 6px 12px 6px;
            }

            .@{ant-prefix}-upload-select-picture-card {
                margin: 0 6px 0 6px;
            }

            .@{ant-prefix}-upload-list-item-actions .@{ant-prefix}-btn-icon-only.@{ant-prefix}-btn-sm {
                padding: 0;
            }
        }
    }

    .@{aputure-prefix}-submit-result {
        padding-bottom: 10px;

        &-box {
            margin: 30px auto 0;
            width: 100%;

            +.@{aputure-prefix}-manual-query-back {
                margin-top: 40px;
            }
    
            .icon-email-success {
                margin: 20px auto 10px;
                width: 80px !important;
                height: 80px !important;
            }
        }

        .result-description {
            width: 85%;
            margin: 0 auto 30px;
            font-size: 14px;

            p {
                margin-bottom: 0;
            }
        }
    }
}
@hack: true;@import "./node_modules/antd/lib/style/color/colorPalette.less";@aputure-prefix: sn-aputure;@ant-prefix: aputure-sn;@phone-max-width: 450px;@font-size-base: 14px;@font-size-lg: 16px;@font-size-sm: 12px;@text-color: #B3B3B3;@body-background: #1F1F1F;@primary-color: #D80B2A;@height-base: 40px;@height-lg: 54px;@height-sm: 36px;@border-color-base: #3D3D3D;@border-radius-base: 10px;@btn-font-size-lg: 18px;@input-bg: inherit;@label-color: #FAFAFA;@form-vertical-label-padding: 0 0 18px;@form-item-margin-bottom: 40px;@upload-picture-card-size: 80px;