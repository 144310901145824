@primary-5: color(
  ~`colorPalette('@{primary-color}', 5) `
);

.custom-upload {
    .@{ant-prefix}-upload-list-item-actions {
        .@{ant-prefix}-btn-icon-only.@{ant-prefix}-btn-sm {
            width: 24px;
            vertical-align: text-bottom;
        }
    }
    .@{ant-prefix}-upload-list-picture-card {
        .@{ant-prefix}-upload-list-picture-card-container {
            margin: 0 20px 0 0;
        }
        .@{ant-prefix}-upload-list-item {
            padding: 0;
            overflow: hidden;
            background-color: #0D0D0D;
        }
    }

    .@{ant-prefix}-upload.@{ant-prefix}-upload-select-picture-card {
        border-style: solid;
        background-color: transparent;

        &:focus-within {
            border-color: @primary-5;
        }
    }

    .@{ant-prefix}-upload-list-item-actions .@{ant-prefix}-btn-icon-only.@{ant-prefix}-btn-sm {
        padding: 0;
    }
}
@hack: true;@import "./node_modules/antd/lib/style/color/colorPalette.less";@aputure-prefix: sn-aputure;@ant-prefix: aputure-sn;@phone-max-width: 450px;@font-size-base: 14px;@font-size-lg: 16px;@font-size-sm: 12px;@text-color: #B3B3B3;@body-background: #1F1F1F;@primary-color: #D80B2A;@height-base: 40px;@height-lg: 54px;@height-sm: 36px;@border-color-base: #3D3D3D;@border-radius-base: 10px;@btn-font-size-lg: 18px;@input-bg: inherit;@label-color: #FAFAFA;@form-vertical-label-padding: 0 0 18px;@form-item-margin-bottom: 40px;@upload-picture-card-size: 80px;