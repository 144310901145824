main.@{aputure-prefix}-main {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: auto;
    background-image: url('~@/assets/images/sncode_bg_pic_pc.png');
    background-size: cover;
    background-position: center bottom;
}

.@{aputure-prefix}-main  {
    .@{aputure-prefix}-container {
        width: 80%;
        max-width: 1100px;
        min-width: 675px;
        padding: 60px 100px;
        margin: 80px auto 40px;
        background-color: #262626;
        border-radius: 20px;
        position: relative;
        opacity: .98;
    }
}

@media screen and (max-width: @phone-max-width) {
    main.@{aputure-prefix}-main {
        background: #262626;
        font-size: 12px;
    }

    .@{aputure-prefix}-main {
        .@{aputure-prefix}-container {
            width: 100%;
            max-width: 100%;
            min-width: 320px;
            margin: 0;
            padding: 50px 20px 40px;
            border-radius: 0;
        }
    }
}
@hack: true;@import "./node_modules/antd/lib/style/color/colorPalette.less";@aputure-prefix: sn-aputure;@ant-prefix: aputure-sn;@phone-max-width: 450px;@font-size-base: 14px;@font-size-lg: 16px;@font-size-sm: 12px;@text-color: #B3B3B3;@body-background: #1F1F1F;@primary-color: #D80B2A;@height-base: 40px;@height-lg: 54px;@height-sm: 36px;@border-color-base: #3D3D3D;@border-radius-base: 10px;@btn-font-size-lg: 18px;@input-bg: inherit;@label-color: #FAFAFA;@form-vertical-label-padding: 0 0 18px;@form-item-margin-bottom: 40px;@upload-picture-card-size: 80px;