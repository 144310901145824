
.svg-icon {
  width: 24px;
  height: 24px;
  vertical-align: -0.18em;
  fill: currentColor;
  overflow: hidden;
  &:focus {
    outline: none
  }
  &.pointer {
    cursor: pointer;
  }
  &-small {
    width: 18px;
    height: 18px;
  }
  &-middle {
    width: 28px;
    height: 28px;
  }
  &-large {
    width: 36px;
    height: 36px;
  }
}
@hack: true;@import "./node_modules/antd/lib/style/color/colorPalette.less";@aputure-prefix: sn-aputure;@ant-prefix: aputure-sn;@phone-max-width: 450px;@font-size-base: 14px;@font-size-lg: 16px;@font-size-sm: 12px;@text-color: #B3B3B3;@body-background: #1F1F1F;@primary-color: #D80B2A;@height-base: 40px;@height-lg: 54px;@height-sm: 36px;@border-color-base: #3D3D3D;@border-radius-base: 10px;@btn-font-size-lg: 18px;@input-bg: inherit;@label-color: #FAFAFA;@form-vertical-label-padding: 0 0 18px;@form-item-margin-bottom: 40px;@upload-picture-card-size: 80px;