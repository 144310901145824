.@{aputure-prefix}-home {
    .@{aputure-prefix}-query-title {
        font-size: 24px;
        font-weight: 800;
        color: #FAFAFA;
        text-align: center;
        margin-bottom: 0;
    }

    .@{aputure-prefix}-query-input {
        margin-top: 60px;
        padding: 14px;
        background-color: #333333;
        border-radius: @border-radius-base;
        border-color: transparent !important;

        &:hover,
        &:focus {
            box-shadow: none;
        }

        .@{ant-prefix}-input-prefix {
            margin-right: 10px;
            // transform: translateY(1px);
        }

        .@{ant-prefix}-input {
            background-color: inherit;
            font-size: 14px;
            font-weight: 500;

            &::placeholder {
                color: #808080;
            }
        }
    }

    .@{aputure-prefix}-result-container {
        overflow: hidden;
        min-height: calc(200px + 5vw);

        .@{aputure-prefix}-query-tip {
            color: #808080;
            text-align: center;
            margin-top: 22px;
            display: flex;
            justify-content: center;
            align-items: start;
            text-align: left;
            font-size: 12px;

            .svg-icon-date {
                margin-right: 5px;
                flex: none;
            }

            p {
                margin: 0;
            }
        }
    }

    .@{aputure-prefix}-result-box {
        width: 80%;
        margin: 60px auto 0;
        position: relative;

        .@{aputure-prefix}-result-content {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            padding-bottom: 40px;
            background-color: #333333;
        }

        .@{aputure-prefix}-result-head {
            line-height: 80px;
            text-align: center;
            font-size: 20px;
            font-weight: 800;
            color: #FAFAFA;
            border-top-left-radius: @border-radius-base;
            border-top-right-radius: @border-radius-base;
            background-color: #4D4D4D;
        }

        .@{aputure-prefix}-result {
            flex: auto;

            &-fail {
                width: 72.3%;
                margin: 40px auto 0;

                p {
                    text-indent: 26px;
                    color: #FAFAFA;
                    font-size: 16px;

                    +p {
                        margin-top: 28px;
                    }
                }
            }

            &-success {
                margin: 50px 60px 0;

                .query-result-item {
                    font-size: 16px;
                    color: #B3B3B3;
                    font-weight: 500;
                    margin-bottom: 30px;

                    span {
                        color: #FAFAFA;
                        font-weight: 800;
                        word-break: break-all;
                    }
                }
            }

            &-bg {
                width: 100%;
                height: 48px;
                position: absolute;
                left: 0;
                bottom: 0;
                background-image: url('~@/assets/images/result_bg.png');
                background-position: bottom;
                background-size: cover;
                background-color: #262626;
            }
        }
    }

    .@{aputure-prefix}-query-button {
        width: 180px;

        &[disabled] {
            color: #FFFFFF;
            border-color: @primary-color;
            background: @primary-color;
            opacity: 0.3;
        }
    }
}

.@{aputure-prefix}-query-examples {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px auto 0;
    width: 90%;

    .@{aputure-prefix}-query-example-item {
        width: 29%;
    }
}

.@{aputure-prefix}-query-foot {
    text-align: center;
    margin-top: 6%;

    .@{aputure-prefix}-query-button+.@{aputure-prefix}-manual-query-tip {
        margin-top: 40px;
    }
}

.@{aputure-prefix}-manual-query-tip {
    color: #B3B3B3;
    font-size: 16px;

    .@{aputure-prefix}-manual-query-link {
        color: #E6E6E6;
        font-weight: bold;
        display: inline-flex;
        align-items: center;
        margin-left: 5px;

        .svg-icon {
            transform: translateY(1.5px);
        }
    }
}


@media screen and (max-width: @phone-max-width) {
    .@{aputure-prefix}-home {
        .@{aputure-prefix}-query-title {
            font-size: calc(20px + 0.15vw);
        }

        .@{aputure-prefix}-query-input {
            margin-top: 50px;
            padding: 8px 12px;
        }

        .@{aputure-prefix}-result-container {
            min-height: calc(160px + 5vw);
        }

        .@{aputure-prefix}-query-button {
            width: 140px;
            height: 44px;
            padding: 4px 15px;
            font-size: 16px;
        }

        .@{aputure-prefix}-result-box {
            width: 95%;
            margin: 30px auto 0;

            .@{aputure-prefix}-result-content {
                padding-bottom: 30px;
            }

            .@{aputure-prefix}-result-head {
                line-height: 50px;
                font-size: calc(18px + 0.15vw);
            }

            .@{aputure-prefix}-result {

                p,
                .query-result-item {
                    font-size: calc(14px + 0.15vw);
                }

                &-fail {
                    width: 90%;
                    p {
                        text-indent: 20px;
                    }
                }

                &-success {
                    width: 90%;
                    margin: 30px auto;

                    .query-result-item {
                        margin-bottom: 24px;
                    }

                }
            }
        }
    }

    .@{aputure-prefix}-query-examples {
        width: initial;
        margin: 20px 0 0 -9px;

        .@{aputure-prefix}-query-example-item {
            width: 33%;
        }
    }

    .@{aputure-prefix}-query-foot {
        margin-top: 15%;

        .@{aputure-prefix}-query-button+.@{aputure-prefix}-manual-query-tip {
            margin-top: 40px;
        }
    }

    .@{aputure-prefix}-manual-query-tip {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 12px;

        .@{aputure-prefix}-manual-query-link {
            margin: 10px 0 0;

        }
    }
}
@hack: true;@import "./node_modules/antd/lib/style/color/colorPalette.less";@aputure-prefix: sn-aputure;@ant-prefix: aputure-sn;@phone-max-width: 450px;@font-size-base: 14px;@font-size-lg: 16px;@font-size-sm: 12px;@text-color: #B3B3B3;@body-background: #1F1F1F;@primary-color: #D80B2A;@height-base: 40px;@height-lg: 54px;@height-sm: 36px;@border-color-base: #3D3D3D;@border-radius-base: 10px;@btn-font-size-lg: 18px;@input-bg: inherit;@label-color: #FAFAFA;@form-vertical-label-padding: 0 0 18px;@form-item-margin-bottom: 40px;@upload-picture-card-size: 80px;