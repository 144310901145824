@import './basics.less';


html {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: '思源黑体', '微软雅黑', '宋体', '新宋体', sans-serif, 'SidusLinkCGeos-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}

#root {
  width: 100%;
  height: 100%;
}

img {
  max-width: 100%;
}

input:-internal-autofill-selected {
  -webkit-text-fill-color: inherit !important;
  background-color: inherit;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
}

.@{ant-prefix}-message {
  .@{ant-prefix}-message-notice-content {
    color: #808080;
  }
}